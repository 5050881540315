import { forwardRef, ForwardRefRenderFunction } from 'react';
import { FieldError } from 'react-hook-form';
import {
  Input as ChakraInput,
  Icon,
  FormLabel,
  FormControl,
  InputProps as ChakraInputProps,
  InputLeftElement,
  InputRightElement,
  InputGroup,
  FormErrorMessage,
} from '@chakra-ui/react';

export interface InputProps extends ChakraInputProps {
  name: string;
  label?: string;
  error?: FieldError;
  leftIcon?: React.ElementType;
  rightIcon?: React.ElementType;
  mask?: string;
  maskChar?: string | null;
}

export const InputBase: ForwardRefRenderFunction<
  HTMLInputElement,
  InputProps
> = ({ name, label, error = null, leftIcon, rightIcon, ...rest }, ref) => {
  return (
    <FormControl isInvalid={!!error}>
      {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <InputGroup>
        {leftIcon ? (
          <InputLeftElement pointerEvents="none" pt="2">
            <Icon as={leftIcon} fontSize="20" />
          </InputLeftElement>
        ) : (
          <></>
        )}
        <ChakraInput
          name={name}
          id={name}
          focusBorderColor="brand.purple"
          variant="filled"
          _hover={{
            bgColor: 'white.900',
          }}
          _placeholder={{
            color: 'gray.400',
          }}
          size="lg"
          ref={ref}
          {...rest}
        />
        {rightIcon ? (
          <InputRightElement pointerEvents="none" pt="2">
            <Icon as={rightIcon} fontSize="20" />
          </InputRightElement>
        ) : (
          <></>
        )}
      </InputGroup>
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export const Input = forwardRef(InputBase);
