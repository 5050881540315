import { Button, Flex, Stack, useToast } from '@chakra-ui/react';

import { Input } from '../Form/Input';

import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '@/shadcn-ui/components/ui/carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { Orbit } from '../Animations/Orbit';
import { translations } from './translations';

export function Login() {
  const [values, setValues] = useState({ email: '', password: '' });
  const [loading, setIsLoading] = useState(false);
  const [logoUrl, setLogoUrl] = useState('/logo3.svg');

  const router = useRouter();
  const toast = useToast();

  const handleInput = (field: string, value: string) => {
    setValues((s) => ({ ...s, [field]: value }));
  };

  const eplusLogo =
    'https://www.agenciaeplus.com.br/wp-content/themes/eplus/images/agencia-eplus-n-logo.png';

  useEffect(() => {
    const whitelabelUrl = 'ecommercedashboard.com.br';

    if (window.location.host === whitelabelUrl) {
      setLogoUrl(eplusLogo);
    }
  }, []);

  const t = translations(router.locale);

  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });

  useEffect(() => {
    if (!emblaApi) return; // Ensure emblaApi is available

    const interval = setInterval(() => {
      emblaApi.scrollNext(); // Scroll to the next slide
    }, 2000); // Change slide every 2 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [emblaApi]);

  return (
    <Flex h="100vh" w="100vw" flexDir="row">
      <Flex
        display={logoUrl === eplusLogo ? 'none' : 'flex'}
        h="100%"
        w={{
          base: 0,
          md: '55%',
        }}
        className="bg-gradient-to-r from-[#1C45D6] to-[#7903EF]"
        flexDir="column"
        alignItems="center"
        justify="center"
        visibility={{
          base: 'hidden',
          md: 'visible',
        }}
      >
        <Orbit
          smallestOrbitRadius={100}
          mediumOrbitRadius={180}
          largestOrbitRadius={260}
        />
        <Flex alignItems={'flex-end'} h="fit" w="full" p={8}>
          <Carousel ref={emblaRef} className="h-xl:hidden w-full">
            <CarouselContent>
              {testimonials.map(({ quote, footer }) => (
                <CarouselItem key={footer} className="max-w-fit">
                  <div>
                    <blockquote className="space-y-2 rounded-md bg-white p-4">
                      <p className="text-lg">&ldquo;{quote}&rdquo;</p>

                      <footer className="text-sm">{footer}</footer>
                    </blockquote>
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
          </Carousel>
        </Flex>
      </Flex>

      <Flex
        h="100%"
        w={{
          base: '100%',
          md: `${logoUrl === eplusLogo ? '100%' : '45%'}`,
        }}
        borderTopLeftRadius={24}
        borderBottomLeftRadius={24}
        bg="#fff"
        alignItems="center"
        justify="center"
      >
        <Flex
          w={{ base: '100%', md: `${logoUrl === eplusLogo ? '40%' : '70%'}` }}
          align="center"
          justify="center"
          flexDir="column"
        >
          <Stack spacing="4" w="70%">
            {/* <CompanyLogo /> */}
            <img alt="logo" src={logoUrl} />

            <form
              onSubmit={async (e) => {
                e.preventDefault();
                setIsLoading(true);
                const data = await signIn<'credentials'>('credentials', {
                  redirect: false,
                  ...values,
                });

                if (!data || data.error) {
                  setIsLoading(false);

                  toast({
                    status: 'error',
                    title: 'Ocorreu um erro ao realizar o login.',
                    description:
                      'Verifique se suas credenciais estão corretas.',
                    position: 'bottom-right',
                    isClosable: true,
                  });

                  return;
                }

                router.push('/app/onboarding');
                setIsLoading(false);
              }}
            >
              <Stack spacing="4">
                <Input
                  name="email"
                  label={t.emailInputLabel}
                  placeholder={t.emailInputPlaceholder}
                  type="email"
                  onChange={(e) => {
                    e.preventDefault();
                    handleInput('email', e.target.value);
                  }}
                />

                <Input
                  name="password"
                  label={t.passwordInputLabel}
                  placeholder={t.passwordInputPlaceholder}
                  type="password"
                  onChange={(e) => {
                    e.preventDefault();
                    handleInput('password', e.target.value);
                  }}
                />
                <Button
                  w={'full'}
                  colorScheme="brand"
                  bgGradient={
                    logoUrl === eplusLogo
                      ? `linear(to-tr, #52bf4b, #52bf4b)`
                      : `linear(to-tr, brand.blue, brand.purple)`
                  }
                  _hover={{ opacity: 0.9 }}
                  fontWeight="bold"
                  isLoading={loading}
                  type="submit"
                >
                  {t.signInButton}
                </Button>
              </Stack>
            </form>
          </Stack>
        </Flex>
      </Flex>
    </Flex>
  );
}

const testimonials = [
  {
    quote:
      'Com o Adstart, a Etraction passou de 12 para 18 clientes por gestor/analista. Queria primeiramente agradecer todo o esforço da equipe. ',
    footer: 'Leandro Dalponte - Fundador da E-traction',
  },
  // {
  //   quote:
  //     'Queria primeiramente agradecer todo o esforço da equipe. Está sendo um grande case para nós e para vocês. Durante a Black Friday, o Adstart nos ajudou no crescimento de 20% do nosso faturamento.',
  //   footer: 'William Theiss - Fundador Arm Fitness',
  // },
];
