import type { GetServerSideProps, NextPage } from 'next';

import { Login as LoginComponent } from '../components/Login';
import { getSession } from 'next-auth/react';
import Head from 'next/head';

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  const session = await getSession({ req });

  if (session) {
    return {
      redirect: {
        destination: '/app/onboarding',
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
};

const Login: NextPage = () => {
  return (
    <>
      <Head>
        <title>Login</title>

        <meta
          name="description"
          content="Automatize o manual e potencialize o criativo"
        />
      </Head>

      <LoginComponent />
    </>
  );
};

export default Login;
