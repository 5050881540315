import { createTranslations } from '../../utils/i18n';

interface Fields {
  signInButton: string;
  emailInputLabel: string;
  emailInputPlaceholder: string;
  passwordInputLabel: string;
  passwordInputPlaceholder: string;
}

export const translations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        signInButton: 'Sign in',
        emailInputLabel: 'E-mail',
        emailInputPlaceholder: 'E.g.: janedoe@example.com',
        passwordInputLabel: 'Password',
        passwordInputPlaceholder: 'Password',
      },
      pt: {
        signInButton: 'Entrar',
        emailInputLabel: 'E-mail',
        emailInputPlaceholder: 'Ex: joao@exemplo.com',
        passwordInputLabel: 'Senha',
        passwordInputPlaceholder: 'Senha',
      },
    },
  });
